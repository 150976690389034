export const Advisory = (props) => {
    return (
      <div id='advisory' className='text-center'>
        <div className='container'>
          <div className='col-md-10 col-md-offset-1 section-title'>
            <h2>Asesorías</h2>
          </div>
          <div className='row'>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className='col-xs-12 col-md-12'>
                    {' '}
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : 'Loading...'}
          </div>
          <div className="row col-lg-12 col-md-12 col-sm-12">
            <a href="https://api.whatsapp.com/send?phone=5214421904451&text=Hola%20Me%20gustar%C3%ADa%20agendar%20una%20asesor%C3%ADa%20gratuita" target="_blank" className='btn btn-custom btn-lg page-scroll'>
                Agendar asesoría
            </a>
        </div>

        </div>
      </div>
    )
  }
  