import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Navigation } from './components/navigation'
import { Header } from './components/header'
import { Features } from './components/features'
import { About } from './components/about'
import { Privacy } from './components/privacy'
import { Services } from './components/services'
import { Pgpf} from './components/pgpf'
import { Pgpm} from './components/pgpm'
import { Pgeliad} from './components/pgeliad'
import { Tpa} from './components/tpa'
import { Cv} from './components/cv'
import { Cvi} from './components/cvi'
import { Dn} from './components/dn'
import { RatPf} from './components/ratPf'
import { RatPm} from './components/ratPm'
import { ChFov} from './components/chFov'
import { ChBnc} from './components/chBnc'
import { Suc} from './components/suc'
import { Soc} from './components/soc'
import { Advisory} from './components/advisory'

import { Gallery } from './components/gallery'
import { Testimonials } from './components/testimonials'
import { Team } from './components/team'
import { Titular } from './components/titular'
import { Contact } from './components/contact'
import { Warning } from './components/warning'
// Importa otros componentes de la landing page

// Importa el componente EManual
import { EManual } from './components/eManual/eManual'

import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const LandingPage = ({ landingPageData }) => {
  return (
    <div>
      <Navigation data={landingPageData.Navigation} />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Privacy data={landingPageData.Privacy} />
      <Services data={landingPageData.Services} />
      <Pgpf data={landingPageData.Pgpf} />
      <Pgpm data={landingPageData.Pgpm} />
      <Pgpm data={landingPageData.Pgpm} />
      <Pgeliad data={landingPageData.Pgeliad} />
      <Tpa data={landingPageData.Tpa} />
      <Cv data={landingPageData.Cv} />
      <Cvi data={landingPageData.Cvi} />
      <Dn data={landingPageData.Dn} />
      <RatPf data={landingPageData.RatPf} />
      <RatPm data={landingPageData.RatPm} />
      <ChFov data={landingPageData.ChFov} />
      <ChBnc data={landingPageData.ChBnc} />
      <Suc data={landingPageData.Suc} />
      <Soc data={landingPageData.Soc} />
      <Gallery />
      <Testimonials data={landingPageData.Testimonials} />
      <Advisory data={landingPageData.Advisory} />
      <Titular data={landingPageData.Titular} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
      <Warning data={landingPageData.Warning} />
      {/* Agrega otros componentes de la landing page aquí */}
    </div>
  )
}

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<LandingPage landingPageData={landingPageData} />} />
        <Route path="/eManual" element={<EManual />} />
      </Routes>
    </Router>
  )
}

export default App
