export const Testimonials = (props) => {
    return (
      <div id='testimonials'>
        <div className='container'>
          <div className='section-title text-center'>
            <h2>Misión y visión</h2>
          </div>
          <div className='row'>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className='col-md-6'>
                    <div className='testimonial'>
                      <div className='testimonial-content'>
                      <div className='testimonial-meta'> - {d.name} </div>
                      <p>"{d.text}"</p>                        
                      </div>
                    </div>
                  </div>
                ))
              : 'loading'}
          </div>
        </div>
      </div>
    )
  }
  