 
  export const Header = (props) => {
    return (
      <header id='header'>
        <div className='intro'>
          <div className='container'>
            <div className='row col-lg-12 col-md-12 col-sm-12 intro-text'>
              <div className="row col-lg-12 col-md-12 col-sm-12">
                <div className="row col-lg-12 col-md-12 col-sm-12">
                  <span>{props.data ? props.data.notario : 'Cargando'}</span>
                </div>
                <div className="row col-lg-12 col-md-12 col-sm-12">
                  <span>{props.data ? props.data.titular : 'Cargando'}</span>
                </div>
              </div>
              <div className="row col-lg-12 col-md-12 col-sm-12">
                <img src={props.data ? props.data.img : 'img/logo-leut.png'} alt='...' className='row team-img' />
              </div>
              <div className="row col-lg-12 col-md-12 col-sm-12">
                <p>{props.data ? props.data.paragraph : 'Cargando'}</p>
                {' '}
              </div>
                  
              <div className="row col-lg-12 col-md-12 col-sm-12">
                <button
                  data-target='#modalPrivaciCenter'
                  data-toggle='modal' 
                  className='btn btn-custom-pr btn-lg page-scroll'
                >
                  Aviso de privacidad
                </button>{' '}
              </div>    
                          
            </div>
          </div>
        </div>
      </header>
    )
  }
    