export const Privacy = (props) => {
    return (
        <div className="modal fade" id="modalPrivaciCenter" tabIndex="-1" role="dialog" aria-labelledby="modalPrivaciCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="modalPrivaciCenterTitle">Aviso de Privacidad.</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>
                        <p>
                            {props.data ? props.data.intro : 'loading'}
                        </p>
                        
                        <h4>{props.data ? props.data.head1 : 'loading'}</h4>

                        <p>
                        {props.data ? props.data.paragraph1 : 'loading'}
                        </p>

                        <div className="privacy-text">
                            <ul>
                                {props.data
                                ? props.data.list1.map((d, i) => (
                                    <li key={`${d}-${i}`}> {d}</li>
                                    ))
                                : 'cargando'}
                            </ul>  
                        </div>
                        <h4>{props.data ? props.data.head2 : 'loading'}</h4>

                        <p>
                        {props.data ? props.data.paragraph2 : 'loading'}
                        </p>
                        
                        <div className="privacy-text">
                            <ul>
                                {props.data
                                ? props.data.list2.map((d, i) => (
                                    <li key={`${d}-${i}`}> {d}</li>
                                    ))
                                : 'cargando'}
                            </ul>  
                        </div>

                        <h4>{props.data ? props.data.head3 : 'loading'}</h4>

                        <p>
                        {props.data ? props.data.paragraph3 : 'loading'}
                        </p>

                        <h4>{props.data ? props.data.head4 : 'loading'}</h4>

                        <p>
                        {props.data ? props.data.paragraph4 : 'loading'}
                        </p>

                        <div className="privacy-text">
                            <ul>
                                {props.data
                                ? props.data.list3.map((d, i) => (
                                    <li key={`${d}-${i}`}> {d}</li>
                                    ))
                                : 'cargando'}
                            </ul>  
                        </div>
                        
                        <h4>{props.data ? props.data.head5 : 'loading'}</h4>

                        <p>
                        {props.data ? props.data.paragraph5 : 'loading'}
                        </p>

                        <h4>{props.data ? props.data.head6 : 'loading'}</h4>

                        <p>
                        {props.data ? props.data.paragraph6 : 'loading'}
                        </p>

                        <div className="privacy-text">
                            <ul>
                                {props.data
                                ? props.data.list4.map((d, i) => (
                                    <li key={`${d}-${i}`}> {d}</li>
                                    ))
                                : 'cargando'}
                            </ul>  
                        </div>

                        <h4>{props.data ? props.data.head7 : 'loading'}</h4>

                        <p>
                        {props.data ? props.data.paragraph7 : 'loading'}
                        </p>

                        <div className="privacy-text">
                            <ul>
                                {props.data
                                ? props.data.list5.map((d, i) => (
                                    <li key={`${d}-${i}`}> {d}</li>
                                    ))
                                : 'cargando'}
                            </ul>  
                        </div>
                    </div>
                    
                </div>
                <div className="modal-footer">
                    <p type="button" className="btn btn-custom-modal btn-lg" data-dismiss="modal">Entendido</p>
                </div>
                </div>
            </div>
        </div>
      )
    }   