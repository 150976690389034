import React, { useState } from "react";

export const Warning = () => {
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="modal" style={{ display: showModal ? "block" : "none" }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
          <span className="fa fa-warning"></span>
            <h5 className="modal-title">Advertencia</h5>
          </div>
          <div className="modal-body">
            Estimado visitante, se le informa que la Notaria 04 de San Juan del Río, Qro. Cuenta con una cuenta bancaria propia y se proporciona únicamente por personal autorizado de la misma, ningún pago se realiza a una cuenta diferente a la oficial.
            <br></br>Bajo ninguna circunstancia utilizamos llamadas o medios electrónicos para solicitar pagos o compartir información.
            <br></br>Recomendamos tomar precauciones e informarse con nuestro personal autorizado antes de realizar cualquier transferencia, depósito o movimiento bancario.
          </div>
          <div className="modal-footer">
            <a className="navbar-brand page-scroll" href="#page-top">
              <img src="img/logo-leut.png" alt="..." className="team-img-nav" />
            </a>
            <a
              type="button"
              className="btn btn-requi-modal btn-lg"
              onClick={closeModal}
            >
              Enterado
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Warning;
