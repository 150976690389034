export const Gallery = (props) => {
    return (
      <div id='portfolio' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>Galería</h2>
            <p>
              Por su ubicación e historia, la Notaría 04 muestra una combinación interesante que contrasta su arquitectura colonial con un mobiliario y equipo tecnológico moderno. 
            </p>
          </div>
          <div className='row'>
            <div className='portfolio-items'>
            <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='portfolio-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href='img/portfolio/07-large-min.jpg'
                      title='Ver imagen'
                      data-lightbox-gallery='gallery1'
                    >
                      <div className='hover-text'>
                        <h4>Fachada principal</h4>
                      </div>
                      <img
                        src='img/portfolio/07-small-min.jpg'
                        className='img-responsive'
                        alt='Ver imagen'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='portfolio-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href='img/portfolio/06-large-min.jpg'
                      title='Ver imagen'
                      data-lightbox-gallery='gallery1'
                    >
                      <div className='hover-text'>
                        <h4>Recepción</h4>
                      </div>
                      <img
                        src='img/portfolio/06-small-min.jpg'
                        className='img-responsive'
                        alt='Ver imagen'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='portfolio-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href='img/portfolio/04-large-min.jpg'
                      title='Ver imagen'
                      data-lightbox-gallery='gallery1'
                    >
                      <div className='hover-text'>
                        <h4>Patio Principal</h4>
                      </div>
                      <img
                        src='img/portfolio/04-small-min.jpg'
                        className='img-responsive'
                        alt='Ver imagen'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='portfolio-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href='img/portfolio/05-large-min.jpg'
                      title='Ver imagen'
                      data-lightbox-gallery='gallery1'
                    >
                      <div className='hover-text'>
                        <h4>Patio Principal</h4>
                      </div>
                      <img
                        src='img/portfolio/05-small-min.jpg'
                        className='img-responsive'
                        alt='Ver imagen'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='portfolio-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href='img/portfolio/03-large-min.jpg'
                      title='Ver imagen'
                      data-lightbox-gallery='gallery1'
                    >
                      <div className='hover-text'>
                        <h4>Oficina del Notario Titular</h4>
                      </div>
                      <img
                        src='img/portfolio/03-small-min.jpg'
                        className='img-responsive'
                        alt='Ver imagen'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='portfolio-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href='img/portfolio/02-large-min.jpg'
                      title='Ver imagen'
                      data-lightbox-gallery='gallery1'
                    >
                      <div className='hover-text'>
                        <h4>Estatua "El bien vs el mal"</h4>
                      </div>
                      <img
                        src='img/portfolio/02-small-min.jpg'
                        className='img-responsive'
                        alt='Ver imagen'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='portfolio-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href='img/portfolio/01-large-min.jpg'
                      title='Ver imagen'
                      data-lightbox-gallery='gallery1'
                    >
                      <div className='hover-text'>
                        <h4>Sala de juntas</h4>
                      </div>
                      <img
                        src='img/portfolio/01-small-min.jpg'
                        className='img-responsive'
                        alt='Ver imagen'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='portfolio-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href='img/portfolio/08-large-min.jpg'
                      title='Ver imagen'
                      data-lightbox-gallery='gallery1'
                    >
                      <div className='hover-text'>
                        <h4>Oficinas segundo piso</h4>
                      </div>
                      <img
                        src='img/portfolio/08-small-min.jpg'
                        className='img-responsive'
                        alt='Ver imagen'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
              <div className='col-sm-6 col-md-4 col-lg-4'>
                <div className='portfolio-item'>
                  <div className='hover-bg'>
                    {' '}
                    <a
                      href='img/portfolio/09-large-min.jpg'
                      title='Ver imagen'
                      data-lightbox-gallery='gallery1'
                    >
                      <div className='hover-text'>
                        <h4>Vista desde segunda planta</h4>
                      </div>
                      <img
                        src='img/portfolio/09-small-min.jpg'
                        className='img-responsive'
                        alt='Ver imagen'
                      />{' '}
                    </a>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  